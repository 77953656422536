import React, {useContext} from 'react';
import Header from '../../components/headerComponent/HeaderComponent';
import Hero from '../../components/hero/Hero';
import "./Home.css";
import Footer from '../../components/footerComponent/FooterComponent';
import Spacer from '../../components/spacer/Spacer';
import Preview from '../../components/preview/Preview';
import IEvent from "../../models/eventItem";
import {sortEventsDescWithLimit} from "../../utils/helpers/sorters";
import {getRandomHero} from "../../utils/helpers/heroPicker";
import {rightMouseBlocker} from "../../utils/helpers/utils";
import {PageInfo} from "../../utils/CONSTANTS";
import {useNavigate} from "react-router-dom";
import {DataContext} from "../../context/DataContext";

function Home() {
    const { allEvents } = useContext(DataContext);
    const sorted: IEvent[] = sortEventsDescWithLimit(allEvents, 5)

    const navigate = useNavigate();

    function goToInfoPage() {
        navigate('/info')
    }

    return (
        <div id={"home-container"} onContextMenu={rightMouseBlocker}>
            <Hero/>
            <Header/>
            <main>
                <section id="club-info">
                    <h1>{PageInfo.home.title}</h1>
                    {PageInfo.home.description.map((line: string) => {
                        return (<p key={line}>{line}</p>)
                    })}
                    <br/>
                    <button className={"btn"} onClick={goToInfoPage}>Lees meer</button>
                </section>

                <Spacer/>
                <section className="recente-activiteiten">
                    {sorted.map((event: IEvent, index: number) => {
                        return (
                            <Preview
                                id={`${event.soort?.toLowerCase()}/${event.id}`}
                                title={event.title}
                                img={getRandomHero(event)}
                                beschrijving={event.beschrijving}
                                key={event.id}
                                soort={event.soort}
                            />
                        );

                    })}
                </section>
            </main>
            <Footer/>
        </div>

    );
}

export default Home;